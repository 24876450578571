import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import BlogContent from "../components/blog-content";
import TableOfContents from '../components/table-of-contents';
import Breadcrumbs from "../components/breadcrumbs"
import Thumbnail from "../components/thumbnail";
import resolveUrl from "../utils/urls/resolve-url";
import Posts from '../components/posts';
import Layout from "../components/layout";
import Icon from "../components/icon";
import Translate from "../components/translate";
import CommentsForm from "../components/comments-form";
import Faq from "../components/faq";

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props} description={props.data?.mysqlTerms?.description}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/blog/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
)

const Vindicators = ({ 
  data: { vindicatorsFirm, allMysqlBlog, mysqlTerms, mysqlBlog, allMysqlFaq }, 
  pageContext : { breadcrumbs },
  location: { pathname }
}) => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const [isFaq, setIsFaq] = useState(false);
  const toggleFaq = (value) => setIsFaq(value ?? !isFaq)

  return (
    <Layout pathname={pathname}>
    <div className={[b.container, b.pt3,'breadcrumbs-mobile',b.ptLg5,"scroll-inner"].join(" ")}>
      <Breadcrumbs theme='white' items={breadcrumbs} />
    </div>
    <div className={[b.container, b.pbLg5, b.pt3].join(" ")}>
      <div className={["blueToGray", b.mb4].join(" ")}>
        <h1 className={["blackToWhite",'big-title', b.mb2].join(" ")}>{mysqlTerms.title}</h1>
      </div>
      <div className={[b.row].join(' ')}>
        {vindicatorsFirm.nodes.map(({title,published_date,description,image_description,term_normalized,terms_path,url,mysqlImages}, i) => (
          <div className={[b.colMd6, b.colLg4, b.colXl3, b.my3].join(' ')} key={i} >
            <div className={['bank-list-products-box',b.h100].join(' ')}>
              <div className={['debtors-box'].join(' ')}>
                  <Thumbnail image={mysqlImages[0]} alt={image_description || ''} />
              </div>
              <div className="post-body">
                <div>
                  <span className={["post-text",'font-size-10','post-text-color'].join(' ')}>{published_date}</span>
                  <div className={[b.h6,'font-size-18', b.mb3, b.mt1].join(' ')}>
                    <Link to={resolveUrl(terms_path,term_normalized,url)} className={["post-link","post-heading-color"].join(' ')}>{title}</Link>
                  </div>
                  <p className={['post-text', 'post-text-color'].join(' ')}>
                    {description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {mysqlBlog.body && <div className={`${b.row} product-page blog-content`}>
        <div className={[b.colXl4, b.mb3, b.orderXlLast].join(' ')}>
          <TableOfContents selector=".post-content h2[id]" />
        </div>

        <section className={`${b.colXl8} blackToGray dark-theme-listing`}>
          <BlogContent content={mysqlBlog.body} />
          
          {allMysqlFaq?.nodes.length > 0 && (
            <>
              <div className="post-content">
                <h2>
                  <Translate tKey="Often asked questions"/>
                </h2>
                
                {allMysqlFaq.nodes.map(({ title, text }, i) => (
                  <Faq title={title} text={text} key={i}/>
                ))}
            
                <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                  <button className={[b.btn,"btn-light","btn-cta","button-256",].join(" ")} onClick={() => { toggleForm();toggleFaq(true); }}>
                    <Icon size={20} icon="Question-mark" className={[b.mr1, "icon-blog"].join(" ")}/>
                    <Translate tKey="Ask your own question"/>
                  </button>
                </div>
              </div>
            </>
          )}
        </section>
      </div>}

      {allMysqlBlog.nodes.length > 0 && <aside className={[b.my5, b.ptLg5].join(" ")}>
        <h2 className={["title", b.dBlock, b.mb4,b.mt3].join(' ')}>Treści powiązane z {mysqlBlog.title}</h2>
        <Posts items={allMysqlBlog.nodes} />
      </aside>}

      <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false);toggleFaq(false); }} formID={mysqlTerms.mysqlId} commentType="entry" faq={isFaq} />
    </div>
    
    </Layout>
  )
}

export default Vindicators

export const query = graphql `
query VindicatorsQuery($mysqlId: Int!, $term_id: Int!, $withoutUrls: [String]) {
  vindicatorsFirm: allMysqlBlog(filter: {blogTerms: {elemMatch: {cluster: {eq: "vindication", nin: ["debt", ""]}}}, status: {eq: "publish"}}) {
    nodes {
      mysqlImages {
        childImageSharp {
          gatsbyImageData(width: 295)
        }
      }
      title
      published_date
      description
      author {
        name
        mysqlId
        mysqlImage {
          childImageSharp {
            gatsbyImageData(width: 70)
          }
        }
      }
      image_description
      term_normalized
      terms_path
      url
    }
  }
  allMysqlBlog(filter: {url: {nin: $withoutUrls}, blogTerms: {elemMatch: {cluster: {ne: "vindication"}}}, term_id: {eq: $term_id}, is_category: {eq: null}, description: {ne: null}, status: {eq: "publish"}}) {
    nodes {
      ...PostData
    }
  }
  mysqlBlog(mysqlId: {eq: $mysqlId}) {
    mysqlId
    body
    intro
    title
  }
  mysqlTerms(mysqlId: {eq: $term_id}) {
    description
    title
    term
    term_normalized
    description_alt
    description_additional
    path
  }
  allMysqlFaq(
    filter: { item: { eq: $mysqlId }, type: { eq: "article" }, answer: { ne: null } },
    sort: { fields: priority, order: DESC }
  ) {
    nodes {
      title: question
      text: answer
      priority
    }
  }
  allMysqlSeo(filter: { item_id: { eq: $term_id }, item_type: { eq: "term" }}) {
    nodes {
      ...SeoData
    }
  }
}
`